import React from 'react';
import { supabase } from '../supabaseClient.ts';

interface LoginPageProps {
  onLogin: () => void;
}

const LoginPage: React.FC<LoginPageProps> = ({ onLogin }) => {
  const handleTwitterLogin = async () => {
    const { error } = await supabase.auth.signInWithOAuth({
      provider: 'twitter',
      options: {
        redirectTo: `${window.location.origin}/dashboard`,
      },
    });

    if (error) {
      console.error('Errore durante il login:', error.message);
      alert('Errore durante il login: ' + error.message);
    } else {
      console.log('Login effettuato con successo!');
      const { data, error: refreshError } = await supabase.auth.refreshSession();
      if (refreshError) {
        console.error('Errore nel refresh della sessione:', refreshError.message);
      } else {
        console.log('Sessione aggiornata:', data.session);
        onLogin();
      }
    }
  };

  return (
    <div className="login-page">
      <button className="button" onClick={handleTwitterLogin}>
        Login with Twitter
      </button>
    </div>
  );
};

export default LoginPage;
