import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import LoginPage from './components/LoginPage.tsx';
import Dashboard from './components/Dashboard.tsx';
import Whitepaper from './components/Whitepaper.tsx';
import { supabase } from './supabaseClient.ts';

import './styles/Global.css';
import './styles/Layout.css';
import './styles/Navbar.css';
import './styles/LoginPage.css';
import './styles/Dashboard.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const fetchSession = async () => {
      const { data, error } = await supabase.auth.getSession();
      if (error) {
        console.error('Errore nel recupero della sessione:', error.message);
      } else {
        console.log('Sessione iniziale:', data.session);
        setIsAuthenticated(!!data.session);
      }

      if (!data.session) {
        const { data: refreshedData, error: refreshError } = await supabase.auth.refreshSession();
        if (refreshError) {
          console.error('Errore nel refresh della sessione:', refreshError.message);
        } else {
          console.log('Sessione aggiornata:', refreshedData.session);
          setIsAuthenticated(!!refreshedData.session);
        }
      }
    };

    fetchSession();

    const { data: subscription } = supabase.auth.onAuthStateChange((event, session) => {
      console.log('Cambio stato autenticazione:', event, session);
      setIsAuthenticated(!!session);
    });

    return () => {
      subscription?.unsubscribe();
    };
  }, []);

  const handleLogout = async () => {
    await supabase.auth.signOut();
    setIsAuthenticated(false);
  };

  return (
    <Router>
      <div className="app">
        {isAuthenticated && (
          <header className="navbar top-navbar">
            <img
              src="/LogoSthackers.gif"
              alt="STHACKERS Logo"
              className="navbar-logo"
            />
          </header>
        )}

        <main className="main-content">
          <Routes>
            {!isAuthenticated ? (
              <Route path="/" element={<LoginPage onLogin={() => setIsAuthenticated(true)} />} />
            ) : (
              <>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/whitepaper" element={<Whitepaper />} />
                <Route path="*" element={<Navigate to="/dashboard" />} />
              </>
            )}
          </Routes>
        </main>

        {isAuthenticated && (
          <footer className="navbar bottom-navbar">
            <Link to="/dashboard">Dashboard</Link>
            <Link to="/whitepaper">Whitepaper</Link>
          </footer>
        )}
      </div>
    </Router>
  );
}

export default App;
