import React from 'react';

const Whitepaper: React.FC = () => {
  return (
    <div className="whitepaper pixel-art">
      <h1>Whitepaper</h1>
      <p>Details about the game and its features will be shared here.</p>
    </div>
  );
};

export default Whitepaper;
