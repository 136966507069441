import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient.ts';
import { useNavigate } from 'react-router-dom';

interface Task {
  taskid: number;
  tasktitle: string;
  tasklink: string;
  tasktype: string;
  points: number;
  state?: 'start' | 'check' | 'completed'; // Stato del bottone
  startTime?: number; // Timestamp del momento in cui il task è passato a "check"
}

const Dashboard: React.FC = () => {
  const [activeTab, setActiveTab] = useState<'profile' | 'rewards'>('profile');
  const [user, setUser] = useState<any>(null);
  const [tasks, setTasks] = useState<Task[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserAndTasks = async () => {
      const { data } = await supabase.auth.getUser();
      if (!data.user) {
        navigate('/'); // Reindirizza alla pagina di login se l'utente non è autenticato
      } else {
        setUser(data.user);
        fetchTasks(data.user.id); // Carica le missioni solo se l'utente è autenticato
      }
    };

    const fetchTasks = async (userid: string) => {
      const { data: tasksData, error: tasksError } = await supabase.from('tasks').select('*');
      if (tasksError) {
        console.error('Errore nel recupero delle tasks:', tasksError.message);
        return;
      }

      // Controlla i task completati nella tabella userpoints
      const { data: completedTasks, error: completedError } = await supabase
        .from('userpoints')
        .select('taskid')
        .eq('userid', userid);

      if (completedError) {
        console.error('Errore nel controllo dei task completati:', completedError.message);
        return;
      }

      // Mappa i task con il loro stato
      const updatedTasks = (tasksData || []).map((task) => ({
        ...task,
        state: completedTasks?.some((completed) => completed.taskid === task.taskid)
          ? 'completed'
          : 'start',
      }));

      setTasks(updatedTasks);
    };

    fetchUserAndTasks();
  }, [navigate]);

  const handleTaskButtonClick = async (task: Task) => {
    if (task.state === 'start') {
      // Apri il link del task in una nuova scheda
      if (task.tasklink) {
        window.open(task.tasklink, '_blank');
      } else {
        alert('Il link per questo task non è disponibile.');
        return;
      }

      // Cambia lo stato a "check" e registra il timestamp del momento
      setTasks((prevTasks) =>
        prevTasks.map((t) =>
          t.taskid === task.taskid
            ? { ...t, state: 'check', startTime: Date.now() }
            : t
        )
      );
    } else if (task.state === 'check') {
      const now = Date.now();

      // Recupera il timestamp del momento in cui è passato a "check"
      const taskStartTime = task.startTime || 0;

      // Controlla se sono trascorsi almeno 10 secondi
      if (now - taskStartTime < 10000) {
        alert('Devi aspettare almeno 10 secondi per completare questo task!');
        return;
      }

      try {
        // Tenta di registrare il completamento del task
        const { data: existingTask, error: checkError } = await supabase
          .from('userpoints')
          .select('*')
          .eq('userid', user.id)
          .eq('taskid', task.taskid)
          .single();

        if (checkError && checkError.code !== 'PGRST116') {
          console.error('Errore nel controllo del task esistente:', checkError.message);
          alert('Errore durante il controllo del task.');
          return;
        }

        if (existingTask) {
          alert('Hai già completato questo task!');
          return;
        }

        // Registra il completamento del task
        const { error: insertError } = await supabase.from('userpoints').insert({
          userid: user.id,
          taskid: task.taskid,
          points: task.points,
          completedat: new Date().toISOString(),
          displayname: user.user_metadata.full_name,
        });

        if (insertError) {
          console.error('Errore nell\'inserimento del task completato:', insertError.message);
          alert('Errore durante il completamento del task.');
          return;
        }

        alert('Task completato con successo!');

        // Cambia lo stato a "completed"
        setTasks((prevTasks) =>
          prevTasks.map((t) => (t.taskid === task.taskid ? { ...t, state: 'completed' } : t))
        );
      } catch (error) {
        console.error('Errore durante il completamento del task:', error.message);
        alert('Errore durante il completamento del task.');
      }
    }
  };

  const handleLogout = async () => {
    await supabase.auth.signOut();
    navigate('/'); // Reindirizza alla pagina di login
  };

  if (!user) {
    return <p>Loading...</p>;
  }

  return (
    <div className="dashboard">
      {/* Tabs */}
      <div className="tabs-container">
        <button
          className={`tab-button ${activeTab === 'profile' ? 'active' : ''}`}
          onClick={() => setActiveTab('profile')}
        >
          Profile
        </button>
        <button
          className={`tab-button ${activeTab === 'rewards' ? 'active' : ''}`}
          onClick={() => setActiveTab('rewards')}
        >
          Rewards
        </button>
      </div>

      {/* Contenuto delle schede */}
      {activeTab === 'profile' && (
        <div className="profile-tab">
          <div className="user-info">
            <img
              src={user.user_metadata.avatar_url}
              alt="Profile"
              className="user-avatar"
            />
            <p className="user-name">{user.user_metadata.full_name}</p>
            <p className="user-date">
              Registered on: {new Date(user.created_at).toLocaleDateString()}
            </p>
            <button className="logout-button" onClick={handleLogout}>
              Logout
            </button>
          </div>
        </div>
      )}

      {activeTab === 'rewards' && (
        <div className="rewards-tab">
          <div className="task-list">
            {tasks.length > 0 ? (
              tasks.map((task) => (
                <div key={task.taskid} className="task-item">
                  <p>{task.tasktitle}</p>
                  <button
                    className={`task-button task-${task.state}`}
                    disabled={task.state === 'completed'}
                    onClick={() => handleTaskButtonClick(task)}
                  >
                    {task.state === 'start'
                      ? 'Start'
                      : task.state === 'check'
                      ? 'Check'
                      : 'Completed'}
                  </button>
                </div>
              ))
            ) : (
              <p>No tasks available.</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
